import { useEffect, useRef, useState } from "react";

import { useTranslation } from 'react-i18next';

// Incode components (Steps)
import Steps from "./incode/Steps";
import FrontId from "./incode/FrontId";
import BackId from "./incode/BackId";
import ProcessId from "./incode/ProcessId";
import Selfie from "./incode/Selfie";
//import FaceMatch from "./incode/FaceMatch";
//import RetrySteps from "./incode/RetrySteps";

// Utilities
import useQuery from "./incode/utils/useQuery";
import { getSession } from "./incode/utils/getSession";

// Containers
import Error from "./containers/Error";
import Loading from "./containers/Loading";
import ProcessFinished from "./containers/ProcessFinished";
import Home from "./containers/Home";
import Layout from "./components/Layout";
import OCRData from "./incode/OCRData";
import OCRProvider from "./contexts/OCRProvider";
import { getAccountInfo } from "./incode/utils/getAccountInfo";
import incode from "./incode.config";

const documentTypes = { "PASSPORT": "Passport", "SSN": "insuranceCard", "NATIONALCARD": "IdentificationCard", "DRIVERSLICENSE": "DriversLicense" }

export default function App() {
  const firstRender = useRef(true);
  const [session, setSession] = useState();
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({});
  //const [liveness, setLiveness] = useState(false);
  //const [userExists, setUserExists] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [holder, setHolder] = useState("");
  const [customer, setCustomer] = useState({});
  const [token, setToken] = useState("");
  const [deeplink, setDeeplink] = useState("");

  const queryParams = useQuery();
  const warmUp = async () => await incode.warmup();

  const { i18n } = useTranslation();

  useEffect(() => {
    const account_id = queryParams.get("account_id");
    const holder = queryParams.get("holder");
    const token = queryParams.get("token");
    const deeplink = queryParams.get("deeplink")? queryParams.get("deeplink") : process.env.REACT_APP_DEEP_LINK;

    const lng = queryParams.get("lang") || 'es';
    process.lng = lng;
    i18n.changeLanguage(lng)

    setAccountId(account_id);
    setHolder(holder);
    setToken(token);
    setDeeplink(deeplink);

    if (firstRender.current) {
      firstRender.current = false;

      getAccountInfo(account_id, token)
      .then(async data => {
        if (data.status === "error" || Object.keys(data.account).length === 0) {
          if(data.code === 403 && !process.env.REACT_APP_USER_SERVER.startsWith('https://demo-api')) {
            window.location.href = `https://davivienda.globalinvest.us`;
          }
          else {
            setError(true);
            setErrorData(data);
          }
          return;
        }
        setCustomer(data);

        getSession(account_id, holder, token)
        .then(session => {
          if (session.status === "error") {
            /*
            if(session.code === 403) {
              window.location.href = `https://davivienda.globalinvest.us`;
            }
            else {
              */
              setError(true);
              setErrorData(session);
            //}
          }
          else {
            warmUp();
            setSession(session.session);
          }
        })
        .catch(error => {
          setErrorData(error);
          setError(true);
        });
      })
      .catch(error => {
        setErrorData(error);
        setError(true);
      });
    }

    /*
    if(window.navigator.userAgent.indexOf('iPhone') === -1) {
      console.log("background : ", window.navigator);
      if (navigator.mediaDevices.getUserMedia !== null) {
        var options = {
          video: true,
          audio: false
        };
              
        navigator.mediaDevices.getUserMedia(options)
        .then((stream) => {
            console.log(stream, "streaming OK");
          }
        ).catch((e) => {
            console.log("background error : " + e.name);
            setErrorData({ "status": "fail", "message": 'Not Allowed Camera Error', "msg_id": "NotAllowedCamError", "value": 0, code: "100" });
            setError(true);
          }
        );
      }
    }
    */

  }, [queryParams, customer, i18n]);

  if (error) return <Layout><Error data={errorData} deeplink={deeplink}/></Layout>;
  if (!session) return <Loading />;

  const onlyFront = [ 'SSN', 'PASSPORT' ];
  const identification_type = holder==='second'? customer.account.joint_identification_type : customer.account.identification_type;

  const goNext = (data) => {
    /*
    typeOfId: "IdentificationCard"
    typeOfId: "Passport"
    typeOfId: "DriversLicense"
    */

    // TODO ver casos de error
    
    if(data && data.typeOfId && data.typeOfId!==documentTypes[identification_type]) {
      setErrorData({ "status": "fail", "message": 'Incorrect Document', "msg_id": "IncorrectDocument", "value": 0, code: "100" });
      setError(true);
      return;
    }

    setStep(step + 1);
  }

  function handleError(e) {
    if (e?.type === "permissionDenied") { // error de camara
      console.log('CAM ER');
      setErrorData({ "status": "fail", "message": 'Not Allowed Camera Error', "msg_id": "NotAllowedCamError", "value": 0, code: "100" });      
    }
    else {
      setErrorData(e);
    }
    
    setError(true);
  }

  if(onlyFront.includes(identification_type)) {
    return (
      <Layout>
        <OCRProvider>
          <Steps currentStep={step}>
            <Home goNext={goNext} customer={customer} holder={holder} error={error} errorData={errorData} deeplink={deeplink}/>
            <FrontId type={identification_type} session={session} onSuccess={goNext} onError={handleError} />
            <ProcessId session={session} onSuccess={goNext} onError={handleError}/>
            <OCRData session={session} onSuccess={goNext} onError={handleError} customer={customer} holder={holder}/>
            <Selfie
              session={session}
              onSuccess={res => {
                //setLiveness(res?.liveness);
                //setUserExists(res?.existingUser);
                goNext();
              }}
              onError={handleError}
            />
            <ProcessFinished accountId={accountId} customer={customer} holder={holder} session={session} token={token} deeplink={deeplink}/>
          </Steps>
        </OCRProvider>
      </Layout>
    );
  }
  else {
    return (
      <Layout>
        <OCRProvider>
          <Steps currentStep={step}>
            <Home goNext={goNext} customer={customer} holder={holder} error={error} errorData={errorData} deeplink={deeplink}/>
            <FrontId type={"front"} session={session} onSuccess={goNext} onError={handleError} />
            <BackId session={session} onSuccess={goNext} onError={handleError} />
            <ProcessId session={session} onSuccess={goNext} onError={handleError}/>
            <OCRData session={session} onSuccess={goNext} onError={handleError} customer={customer} holder={holder}/>
            <Selfie
              session={session}
              onSuccess={res => {
                //setLiveness(res?.liveness);
                //setUserExists(res?.existingUser);
                goNext();
              }}
              onError={handleError}
            />
            <ProcessFinished accountId={accountId} customer={customer} holder={holder} session={session} token={token} deeplink={deeplink}/>
          </Steps>
        </OCRProvider>
      </Layout>
    );
  }
}
